import React, { useState, useEffect } from 'react';
import { Grid, Tabs, Tab, Box, IconButton, Hidden, FormControl, FormGroup, FormControlLabel, Checkbox, FormHelperText, RadioGroup, Radio, Divider, Collapse, Link } from '@mui/material';
import { ArrowRight, ArrowLeft, KeyboardArrowUp, Circle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useCookies } from 'react-cookie';
import { ListContainer } from 'components/Items/Container';
import { SearchForm, DateForm, TimeForm, SelectForm, TextForm, NumberForm } from 'components/Items/Form';
import { SmallSubmitButton, SmallCancelButton } from 'components/Items/Button';
import { DataCalendarMonth, DataCalendarWeek, DataCalendarDay } from 'components/Items/Table';
import { getWeek, YyyyMmDd, DayKo, ScheduleKindKo, ServiceKo, SelectScheduleBorderColor, SelectScheduleBgColor } from 'components/Function/ChangeText';
import { SessionKind, Service, RepeatDay, ScheduleKind, RepeatCount } from 'components/Function/AddDatas';
import { FormModal, DetailModal, ConfirmModal } from 'components/Items/Modal';

const ScheduleListView = (props) => {
    const navigate = useNavigate();
    const schedule = props.schedule;
    const setSchedule = props.setSchedule;
    const rooms = props.rooms;
    const setRooms = props.setRooms;
    const [cookies] = useCookies(['Status']);
    const status = Number(cookies.Status);
    const [search, setSearch] = useState('');
    const [search2, setSearch2] = useState('');
    const [targetDate, setTargetDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [tab, setTab] = useState(1);
    const [tab2, setTab2] = useState(status === 3 ? 0 : 1);
    const [selectSchedules, setSelectSchedules] = useState([]);
    const [openScheduleDetailModal, setOpenScheduleDetailModal] = useState(false);
    const [openScheduleCreateModal, setOpenScheduleCreateModal] = useState(false);
    const [openScheduleDeleteModal, setOpenScheduleDeleteModal] = useState(false);
    const [disabledCreateBtn, setDisabledCreateBtn] = useState(true);
    const [selectSchdule, setSelectSchedule] = useState({});
 
    const nextSchdule = () => {
        if(tab === 0){
            setTargetDate(new Date(targetDate.setMonth(targetDate.getMonth()+1)));
        }else if(tab === 1){
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()+7)));
        }else{
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()+1)));
        }
    };

    const beforeSchedule = () => {
        if(tab === 0){
            setTargetDate(new Date(targetDate.setMonth(targetDate.getMonth()-1)));
        }else if(tab === 1){
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()-7)));
        }else{
            setTargetDate(new Date(targetDate.setDate(targetDate.getDate()-1)));
        }
    };

    const handleClickToday = () => {
        const date = new Date(new Date().setHours(0, 0, 0, 0));
        setTargetDate(date);
        handleClickSchedule(date);
    };

    const handleClickDay = (date) => {
        setTargetDate(date);
        handleClickSchedule(date);
    };
    const handleClickCreateSchdule = (date, time) => {
        let startDate = '';
        let startTime = '';
        let endTime = '';
        if(date){
            startDate = `${date.getFullYear()}-${date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`}-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
            if(time){
                startTime = time;
                let tumpTime = new Date(`1980-01-01 ${time}`);
                tumpTime = new Date(tumpTime.setMinutes(tumpTime.getMinutes() + 50));
                const tumpHours = tumpTime.getHours() > 9 ? tumpTime.getHours() : `0${tumpTime.getHours()}`;
                const tumpMinutes = tumpTime.getMinutes() > 9 ? tumpTime.getMinutes() : `0${tumpTime.getMinutes()}`;
                endTime = `${tumpHours}:${tumpMinutes}`;
            }
        }
        setSchedule({
            Client_id: '',
            Counselor_id: props.user._id,
            StartDate: startDate,
            StartTime: startTime,
            EndTime: endTime,
            Service: '',
            State: '',
            Status: 1,
            Room_id: '',
            Kind: { State: props.user.Status === 3 ? 2 : 1, Detail: '' },
            Repeat: { State: false, Count: '', Days: '' },
            IsFee: false,
            Fee: '',
            Memo: ''
        });
        setOpenScheduleCreateModal(true);
    };
    const handleClickDeleteSchedule = (data) => {
        setSelectSchedule(data);
        setOpenScheduleDetailModal(false);
        setOpenScheduleDeleteModal(true);
    };
    const handleCloseDeleteSchedule = () => {
        setSelectSchedule({});
        setOpenScheduleDeleteModal(false);
    };

    const handleClickSchedule = (date) => {
        let nextDay = new Date(new Date(date).setDate(date.getDate()+1));
        const tumpDatas = filterDatas(props.schedules).filter((item) => (new Date(item.StartDate) > date && new Date(item.StartDate) < nextDay));
        if(tumpDatas.length > 0){
            setSelectSchedules(tumpDatas);
            setOpenScheduleDetailModal(true);
        }else{
            return false;
        }
    };

    const handleCloseScheduleDetailModal = () => {
        setOpenScheduleDetailModal(false);
        setSelectSchedules([]);
    };
    const handleClickOpenSchedule = (key) => {
        let openSelectSchdules = [...selectSchedules];
        openSelectSchdules[key].Open = openSelectSchdules[key].Open ? false : true;
        setSelectSchedules(openSelectSchdules);
    };

    const filterDatas = (datas) => {
        let tumpDatas = datas;
        if(tab2 === 1){
            tumpDatas = tumpDatas.filter((item) => (item.Counselor_id._id === props.user._id));
        }else if(tab2 === 2){ 
            tumpDatas = tumpDatas.filter((item) => (props.teamMembers.indexOf(item.Counselor_id._id) > -1));
        }
        if(search){
            tumpDatas = tumpDatas.filter((item) => (item.KindDetailKo.indexOf(search) > -1 || item.Client_id?.Name_decrypt.indexOf(search) > -1 || item.Counselor_id?.Name.indexOf(search) > -1 || item.Room_id?.Name.indexOf(search) > -1));
        }
        return tumpDatas;
    };

    const handleChangeStartTime = (e) => {
        let changeSchedule = { ...schedule };
        changeSchedule.StartTime = e.target.value;
        let tumpTime = new Date(`1980-01-01 ${e.target.value}`);
        tumpTime = new Date(tumpTime.setMinutes(tumpTime.getMinutes() + 50));
        const tumpHours = tumpTime.getHours() > 9 ? tumpTime.getHours() : `0${tumpTime.getHours()}`;
        const tumpMinutes = tumpTime.getMinutes() > 9 ? tumpTime.getMinutes() : `0${tumpTime.getMinutes()}`;
        changeSchedule.EndTime = `${tumpHours}:${tumpMinutes}`;
        setSchedule(changeSchedule)
    };
    const handleChangeRepeatCount = (e) => {
        let changeSchedule = { ...schedule };
        changeSchedule.Repeat.Count = e.target.value;
        setSchedule(changeSchedule);
    };
    const handleChangeRepeatDays = (e) => {
        let changeSchedule = { ...schedule };
        changeSchedule.Repeat.Days = e.target.value;
        setSchedule(changeSchedule);
    };

    const handleResetRooms = () => {
        let changeRooms = [...rooms];
        for(let i = 0; i < changeRooms.length; i++){
            changeRooms[i].disabled = false;
        }
        if(schedule.StartDate && schedule.StartTime){
            for(let i = 0; i < changeRooms.length; i++){
                let tumpDatas = changeRooms[i].Schedule_ids.filter((item) => (new Date(item.StartDate) - new Date(schedule.StartDate) === 0));
                tumpDatas = tumpDatas.filter((item) => ((
                    new Date(`2000-01-01 ${schedule.StartTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${schedule.StartTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                ) || (
                    new Date(`2000-01-01 ${schedule.EndTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${schedule.EndTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                )));
                if(tumpDatas.length > 0){
                    changeRooms[i].disabled = true;
                }
            }
        }
        setRooms(changeRooms);
    };

    const handleClientId = (e) => {
        if( status === 3 ) {
            // 값에 따른 상담자 가져오기
            props.handleClientId(e.target.value);
            setSchedule({ ...schedule, Client_id: e.target.value, Counselor_id: '' });
        }else{
            setSchedule({ ...schedule, Client_id: e.target.value });
        }
        // 값 설정
    }
    
    useEffect(() => {
        handleResetRooms();
    }, [schedule.StartDate, schedule.StartTime]);

    useEffect(() => {
        let tump = false;
        if(schedule.Kind.State === 1){
            if(!schedule.Client_id){
                tump = true;
            }
            if(!schedule.Kind.Detail){
                tump = true;
            }
            if(!schedule.StartDate){
                tump = true;
            }
            if(!schedule.StartTime){
                tump = true;
            }
            if(!schedule.Service){
                tump = true;
            }
            if(!schedule.Counselor_id){
                tump = true;
            }
        }else{
            if(!schedule.Kind.Detail){
                tump = true;
            }
            if(!schedule.StartDate){
                tump = true;
            }
            if(!schedule.StartTime){
                tump = true;
            }
        }
        setDisabledCreateBtn(tump);
    }, [schedule]);

    return(
        <>
        <ListContainer title='스케줄 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} justifyContent={'flex-end'} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid item xs={6} container justifyContent={'flex-end'} spacing={1}>
                        <Grid item>
                            <SmallCancelButton 
                                label='오늘'
                                handleClick={handleClickToday}
                            />
                        </Grid>
                        <Grid item>
                            <SmallSubmitButton 
                                label='스케줄 생성'
                                handleClick={() => handleClickCreateSchdule()}
                                disabled={props.loading || schedule.Counselor_id === ''}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} md={4.5}>
                        <Box sx={{ marginBottom: '16px' }}>
                            <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)} variant='fullWidth'>
                                <Tab label='월' tabIndex={0}/>
                                <Tab label='주' tabIndex={1}/>
                                <Tab label='일' tabIndex={2}/>
                            </Tabs>
                        </Box>
                        <Hidden mdUp={true}>
                        <Box sx={{ marginBottom: '16px' }}>
                            <Tabs value={tab2} onChange={(e) => setTab2(e.target.tabIndex)} variant='fullWidth'>
                                <Tab label='전체' tabIndex={0}/>
                                <Tab label='개인' tabIndex={1}/>
                                <Tab label='팀' tabIndex={2}/>
                            </Tabs>
                        </Box>
                        </Hidden>
                    </Grid>
                    <Grid container item xs={12} md={3} justifyContent={'center'} alignItems={'center'}>
                        <Grid item>
                            <IconButton onClick={beforeSchedule}>
                                <ArrowLeft />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <h3 style={{ margin: 0 }}>{targetDate.getFullYear()}. {targetDate.getMonth()+1}. {tab === 1 ? `${getWeek(targetDate)}W` : null}{tab === 2 ? `${targetDate.getDate()}` : null}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={nextSchdule}>
                                <ArrowRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item md={4.5}>
                        <Hidden mdDown={true}>
                        <Box sx={{ marginBottom: '16px' }}>
                            <Tabs value={tab2} onChange={(e) => setTab2(e.target.tabIndex)} variant='fullWidth'>
                                <Tab label='전체' tabIndex={0}/>
                                <Tab label='개인' tabIndex={1}/>
                                <Tab label='팀' tabIndex={2}/>
                            </Tabs>
                        </Box>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
            {tab === 0 ?
            <DataCalendarMonth
                date={targetDate}
                datas={filterDatas(props.schedules)}
                handleClick={handleClickDay}
                handleClickCreateSchdule={handleClickCreateSchdule}
            />:
            tab === 1 ?
            <DataCalendarWeek
                date={targetDate}
                datas={filterDatas(props.schedules)}
                handleClick={handleClickDay}
                handleClickCreateSchdule={handleClickCreateSchdule}
            />:
            <DataCalendarDay
                date={targetDate}
                datas={filterDatas(props.schedules)}
                handleClickCreateSchdule={handleClickCreateSchdule}
            />}
        </ListContainer>
        <DetailModal
            title={`${YyyyMmDd(targetDate)} (${DayKo(targetDate)})`}
            open={openScheduleDetailModal}
            handleClose={handleCloseScheduleDetailModal}
            height='full'
        >
            <Grid style={{ marginBottom: '16px', marginTop: '8px' }}>
                <SearchForm
                    value={search2}
                    handleChange={(e) => setSearch2(e.target.value)}
                />
            </Grid>
            {selectSchedules.map((item, i) => (
            <Grid key={i}>
                <Grid container style={{ marginBottom: '16px', marginTop: i === 0 ? 0 : '16px' }} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={10} container spacing={1}>
                        <Grid item>
                            <Circle className={`schedule_circle_color${SelectScheduleBorderColor(item.Kind.Detail, item.Date, item.State, item.Visit)}`}/>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={`schedule_bg_modal schedule_bg_color${SelectScheduleBgColor(item.Kind.Detail)}`}>
                                {ScheduleKindKo(item.Kind.Detail)}
                            </div>
                        </Grid>
                        <Grid item>
                            <Link onClick={() => navigate(`/schedule/${item.ScheduleNo}`)}>&#91;{item.StartTime}&#93; {item.Counselor_id.Name} {item.Client_id ? `🤝 ${item.Client_id.Name_decrypt}` : null}</Link>
                        </Grid>
                        <Grid item>
                            <span style={{ color: '#5E5E5E'}}>{item.Room_id ? item.Room_id.Name : ''}</span>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton onClick={() =>handleClickOpenSchedule(i)}>
                            <KeyboardArrowUp style={{ transform: `rotate(${item.Open ? 0 : 180}deg)`, transition: 'all ease 0.25s' }} />
                        </IconButton>
                    </Grid>
                    <Collapse in={item.Open} style={{ width: '100%' }}>
                        {item.Kind.State === 1 ?
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <p>상담자</p>
                                <div className='div_data_value'>{item.Counselor_id.Name}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>내담자</p>
                                <div className='div_data_value'>{item.Client_id.Name_decrypt ? `${item.Client_id.Name_decrypt} (${item.Client_id.NickName})` : item.Client_id.NickName}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <p>상담 일</p>
                                <div className='div_data_value'>{YyyyMmDd(item.StartDate)}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 시작 시간</p>
                                <div className='div_data_value'>{item.StartTime}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 종료 시간</p>
                                <div className='div_data_value'>{item.EndTime}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 종류</p>
                                <div className='div_data_value'>{ScheduleKindKo(item.Kind.Detail)}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>상담 방식</p>
                                <div className='div_data_value'>{ServiceKo(item.Service)}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <p>메모</p>
                                <div className='div_data_value'>{item.Memo ? item.Memo : 'ㅤ'}</div>
                            </Grid>
                        </Grid>:
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <p>이름</p>
                                <div className='div_data_value'>{item.Counselor_id.Name}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>날짜</p>
                                <div className='div_data_value'>{YyyyMmDd(item.StartDate)}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>시작 시간</p>
                                <div className='div_data_value'>{item.StartTime}</div>
                            </Grid>
                            <Grid item xs={6}>
                                <p>스케줄 종류</p>
                                <div className='div_data_value'>{ScheduleKindKo(item.Kind.Detail)}</div>
                            </Grid>
                        </Grid>}
                        <Grid container justifyContent={'flex-end'} spacing={1} style={{ marginTop: '16px'}}>
                            {item.Counselor_id._id === props.user._id || props.user.Status === 3 || props.user.Status === 1?
                            <Grid item>
                                <SmallCancelButton
                                    label='삭제'
                                    handleClick={() => handleClickDeleteSchedule(item)}
                                />
                            </Grid>:null}
                            <Grid item>
                                <SmallSubmitButton
                                    label='상세'
                                    handleClick={() => navigate(`/schedule/${item.ScheduleNo}`)}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
                <Divider />
            </Grid>
            ))}   
        </DetailModal>
        <FormModal
            title='스케줄 생성'
            open={openScheduleCreateModal}
            handleClose={() => setOpenScheduleCreateModal(false)}
            submitLabel='생성'
            disabled={disabledCreateBtn}
            handleClickSubmit={() => {props.handleClickCreateBtn();setOpenScheduleCreateModal(false);}}
        >            
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    스케줄
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={1} control={<Radio checked={schedule.Kind.State === 1}/>} label={'상담'} onChange={() => setSchedule({ ...schedule, Kind: { State: 1, Detail: '' }})}/>
                    <FormControlLabel value={2} control={<Radio checked={schedule.Kind.State === 2}/>} label={'기타'} onChange={() => setSchedule({ ...schedule, Kind: { State: 2, Detail: '' }})}/>
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {schedule.Kind.State === 1 ?
            <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <SelectForm
                        label='내담자'
                        required={true}
                        error={false}
                        value={schedule.Client_id}
                        datas={props.clients}
                        handleChange={handleClientId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectForm
                        label='상담 종류'
                        required={true}
                        error={false}
                        value={schedule.Kind.Detail}
                        datas={SessionKind()}
                        handleChange={(e) => setSchedule({ ...schedule, Kind: { State: 1, Detail: e.target.value }})}
                    />
                </Grid>
            </Grid>
            <DateForm
                label='상담 일'
                required={true}
                error={false}
                value={schedule.StartDate}
                handleChange={(e) => setSchedule({ ...schedule, StartDate: e.target.value })}
            />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TimeForm
                        label='상담 시작 시간'
                        required={true}
                        error={false}
                        value={schedule.StartTime}
                        handleChange={handleChangeStartTime}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimeForm
                        label='상담 종료 시간'
                        required={true}
                        error={false}
                        value={schedule.EndTime}
                        handleChange={(e) => setSchedule({ ...schedule, EndTime: e.target.value })}
                    />
                </Grid>
            </Grid>
            <SelectForm
                label='서비스 방식'
                required={true}
                error={false}
                value={schedule.Service}
                datas={Service()}
                handleChange={(e) => setSchedule({ ...schedule, Service: e.target.value })}
            />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <SelectForm
                        label='상담자'
                        required={true}
                        error={false}
                        disabled={false}
                        value={schedule.Counselor_id}
                        datas={props.user ? [{ value: props.user._id, label: props.user.Name }] : []}
                        handleChange={(e) => setSchedule({ ...schedule, Counselor_id: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectForm
                        label='상담실'
                        required={false}
                        error={!Boolean(schedule.StartDate) || !Boolean(schedule.StartTime)}
                        disabled={!Boolean(schedule.StartDate) || !Boolean(schedule.StartTime)}
                        errorMessage={'상담 일 및 시간을 먼저 설정해주세요.'}
                        cancel={true}
                        value={schedule.Room_id}
                        datas={props.rooms}
                        handleChange={(e) => setSchedule({ ...schedule, Room_id: e.target.value })}
                    />
                </Grid>
            </Grid>
            </>:
            <>
            <SelectForm
                label='스케줄 종류'
                required={true}
                error={false}
                value={schedule.Kind.Detail}
                datas={ScheduleKind()}
                handleChange={(e) => setSchedule({ ...schedule, Kind: { State: 2, Detail: e.target.value }})}
            />
            <DateForm
                label='일정 일'
                required={true}
                error={false}
                value={schedule.StartDate}
                handleChange={(e) => setSchedule({ ...schedule, StartDate: e.target.value })}
            />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TimeForm
                        label='일정 시작 시간'
                        required={true}
                        error={false}
                        value={schedule.StartTime}
                        handleChange={handleChangeStartTime}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimeForm
                        label='일정 종료 시간'
                        required={true}
                        error={false}
                        value={schedule.EndTime}
                        handleChange={(e) => setSchedule({ ...schedule, EndTime: e.target.value })}
                    />
                </Grid>
            </Grid>
            </>}
            <TextForm
                label='메모'
                required={false}
                error={false}
                value={schedule.Memo}
                rows={4}
                handleChange={(e) => setSchedule({ ...schedule, Memo: e.target.value })}
            />
            {schedule.Kind.State === 1 ?
            <Grid container spacing={1}>
                <Grid item xs={4}>
                <FormControl fullWidth>
                    <Grid style={{ marginBottom: '8px' }}>
                        상담비 지불 여부
                    </Grid>
                    <RadioGroup row>
                        <FormControlLabel value={true} onChange={() => setSchedule({ ...schedule, IsFee: true, Fee: '' })} control={<Radio checked={Boolean(schedule.IsFee)} />} label={'O'} />
                        <FormControlLabel value={false} onChange={() => setSchedule({ ...schedule, IsFee: false, Fee: '' })} control={<Radio checked={!Boolean(schedule.IsFee)} />} label={'X'} />
                    </RadioGroup>
                    <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <NumberForm
                        label='상담비'
                        required={false}
                        error={false}
                        // disabled={!Boolean(schedule.IsFee)}
                        placeholder='상담비를 입력해 주세요.'
                        value={schedule.Fee}
                        handleChange={(e) => setSchedule({ ...schedule, Fee: e.target.value })}
                    />
                </Grid>
            </Grid>:null}
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Grid style={{ marginBottom: '8px' }}>
                            반복 여부
                        </Grid>
                        <FormGroup row>
                            <FormControlLabel 
                                onChange={() => setSchedule({ ...schedule, Repeat: { State: !Boolean(schedule.Repeat.State), Count: '', Days: '' }})} 
                                control={<Checkbox checked={Boolean(schedule.Repeat.State)}/>} 
                            />
                        </FormGroup>
                        <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <SelectForm
                        label='반복 횟수'
                        required={false}
                        error={false}
                        disabled={!Boolean(schedule.Repeat.State)}
                        value={schedule.Repeat.Count}
                        datas={RepeatCount()}
                        handleChange={handleChangeRepeatCount}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectForm
                        label='반복 주기'
                        required={false}
                        error={false}
                        disabled={!Boolean(schedule.Repeat.State)}
                        value={schedule.Repeat.Days}
                        datas={RepeatDay()}
                        handleChange={handleChangeRepeatDays}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <FormControlLabel 
                            value={schedule.SendKakao}
                            onChange={() => setSchedule({ ...schedule, SendKakao: !schedule.SendKakao })}
                            control={<Checkbox checked={schedule.SendKakao}/>}
                            label={`카카오톡 전송 여부`}
                        />
                        <FormHelperText style={{ marginLeft: 0, marginTop: 0, whiteSpace: 'nowrap' }}>* 선택 시 상담 2일전 내담자의 전화번호로 카카오톡 메시지가 자동 발송됩니다.</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </FormModal>
        <ConfirmModal
            title='스케줄 삭제'
            icon='error'
            open={openScheduleDeleteModal}
            handleClose={handleCloseDeleteSchedule}
            handleClickSubmit={() => {props.handleClickDeleteBtn(selectSchdule);handleCloseDeleteSchedule();}}
            submitLabel='삭제'
        >
            스케줄을 삭제 하시겠습니까? 삭제된 스케줄은 복구가 불가능합니다.
        </ConfirmModal>
        </>
    );
};

export default ScheduleListView;