import React from 'react';
import { Grid } from '@mui/material';
import { DetailContainer } from 'components/Items/Container';
import { ItemShortForm, ItemMultipleChoice, ItemLikert, ItemShortFormOther } from 'components/Items/View';
import { TextForm, DateForm, TimeForm, NumberForm } from 'components/Items/Form';
import { YyyyMmDdHhMm } from 'components/Function/ChangeText';

const BasicView = (props) => {
    const scales = props.scales;
    const option1 = [13, 26, 39, 49, 2, 19, 29, 50, 12, 24, 35, 48, 9, 25, 8, 20, 36, 10, 23, 37, 47, 6, 22 ,32, 44, 3, 31, 5, 21, 40, 43, 14, 17, 27, 46, 1, 15, 34, 38, 7, 33, 45, 4, 11, 16, 18, 28, 30, 41, 42];
    const subScale1 = ['① 어머니에 대한 태도', '① 어머니에 대한 태도', '① 어머니에 대한 태도', '① 어머니에 대한 태도',
                        '② 아버지에 대한 태도', '② 아버지에 대한 태도', '② 아버지에 대한 태도', '② 아버지에 대한 태도',
                        '③ 가족에 대한 태도', '③ 가족에 대한 태도', '③ 가족에 대한 태도', '③ 가족에 대한 태도',
                        '④ 여성에 대한 태도', '④ 여성에 대한 태도',
                        '⑤ 남성에 대한 태도', '⑤ 남성에 대한 태도', '⑤ 남성에 대한 태도',
                        '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도',
                        '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도',
                        '⑧ 권위자에 대한 태도', '⑧ 권위자에 대한 태도',
                        '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도',
                        '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도',
                        '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도',
                        '⑫ 과거에 대한 태도', '⑫ 과거에 대한 태도', '⑫ 과거에 대한 태도',
                        '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도',
                        '⑭ 목표에 대한 태도', '⑭ 목표에 대한 태도', '⑭ 목표에 대한 태도'];
    const option2 = [15, 23, 14, 27, 1, 8, 11, 12, 16, 18, 22, 34, 13, 28, 36, 9, 17, 31, 37, 2, 6, 10, 35, 4, 20, 21,24, 25, 30, 32, 33, 29, 7, 38, 3, 5, 19, 26];
    const subScale2 = ['① 어머니에 대한 태도', '① 어머니에 대한 태도',
                        '② 아버지에 대한 태도', '② 아버지에 대한 태도',
                        '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계',
                        '④ 학교생활에 대한 태도', '④ 학교생활에 대한 태도', '④ 학교생활에 대한 태도',
                        '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도',
                        '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도',
                        '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', 
                        '⑧ 과거에 대한 태도',
                        '⑨ 미래에 대한 태도', '⑨ 미래에 대한 태도',
                        '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도'];
    return(
        <>
        
        <Grid container>
            {scales ? 
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <h4 style={{ width: '380px', borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>기본 서류</h4>
                    </Grid>
                </Grid>
                {scales.map((item, i) => (
                <Grid container style={{ marginBottom: '8px'}}>
                    <Grid container item xs={8} alignItems={'center'}>
                        <Grid item>
                            <b>{item.Scale_id.Label}</b>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid style={{ marginBottom : '16px' }}>
                            {item.Scale_id.Detail}
                        </Grid>
                        {item.Scale_id._id === '653f90eba149184a6bc2b8b4' ?
                        item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            <ItemShortFormOther 
                                item={item.Scale_id?.Item_ids[`${option1[j]-1}`]} 
                                no={j+1} 
                                scale={item.Scale_id} 
                                subScale={j === 0 || subScale1[j] !== subScale1[j-1]}
                                subScales={subScale1}
                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id === item.Scale_id?._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]}
                            />
                        </Grid>
                        ))
                        :
                        item.Scale_id._id === '653fc2bba149184a6bc2e2f2' ?
                        item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            <ItemShortFormOther 
                                item={item.Scale_id?.Item_ids[`${option2[j]-1}`]} 
                                no={j+1} 
                                scale={item.Scale_id} 
                                subScale={j === 0 || subScale2[j] !== subScale2[j-1]}
                                subScales={subScale2}
                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id === item.Scale_id?._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]}
                            />
                        </Grid>
                        ))
                        :
                        item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            {q.ItemKind === 1 ?
                            <ItemShortForm 
                                item={q} 
                                no={j+1} 
                                scale={item.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            q.ItemKind === 2 ?
                            <ItemMultipleChoice 
                                item={q}
                                no={j+1} 
                                scale={item.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            q.ItemKind === 3 ?
                            <ItemLikert 
                                item={q} 
                                no={j+1} 
                                scale={item.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            null}
                        </Grid>
                        ))}
                    </Grid>
                </Grid>
                ))}
            </Grid>:
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <h4>기본 서류가 없습니다.</h4>
                    </Grid>
                </Grid>
            </Grid>}
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='접수 면접 기록'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Receipt?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextForm
                                label='면접자'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Name ? props.client.Receipt.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateForm
                                label='접수면접 날짜 및 시간'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Day}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimeForm
                                label='ㅤ'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Time}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Note}
                                rows={'auto'}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.createdAt ? YyyyMmDdHhMm(props.client.Receipt.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='검사 관련 노트'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Reference && props.client.Reference.length > 0 ? 'primary' : 'secondary'}
                >
                    {props.client.Reference && props.client.Reference.length > 1 ?
                    props.client.Reference.map((item, i) => (
                    <Grid container spacing={1} justifyContent={'center'}>
                        <Grid item xs={4}>
                            <TextForm
                                label='검사 제목'
                                required={false}
                                error={false}
                                value={item.Title}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={item.Name ? item.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateForm
                                label='날짜'
                                required={false}
                                error={false}
                                value={item.Day}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='검사 결과 및 관련 내용'
                                required={false}
                                error={false}
                                value={item.Note}
                                rows={'auto'}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={item.createdAt ? YyyyMmDdHhMm(item.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                        {props.client.Reference.length !== i+1 ? <Grid item xs={11.5} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', marginBottom: '16px' }} /> : null}
                    </Grid>
                    )):
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextForm
                                label='검사 제목'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Title}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Name ? props.client.Reference[0]?.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateForm
                                label='날짜'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Day}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='검사 결과 및 관련 내용'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Note}
                                disabled={true}
                                rows={'auto'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.createdAt ? YyyyMmDdHhMm(props.client.Reference[0]?.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>}
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='종결 보고서'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Report?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.Report?.Name ? props.client.Report.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateForm
                                label='상담 시작 일'
                                required={false}
                                error={false}
                                value={props.client.Report?.StartDay}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateForm
                                label='상담 종료 일'
                                required={false}
                                error={false}
                                value={props.client.Report?.EndDay}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.Report?.Note}
                                disabled={true}
                                rows={'auto'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberForm
                                label='상담 횟수'
                                required={false}
                                error={false}
                                value={props.client.Report?.Count}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Report?.createdAt ? YyyyMmDdHhMm(props.client.Report.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='의뢰서'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Request?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.Request?.Name ? props.client.Request.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.Request?.Note}
                                disabled={true}
                                rows={'auto'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Request?.createdAt ? YyyyMmDdHhMm(props.client.Request.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='기타'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.ETC?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextForm
                                label='제목'
                                required={false}
                                error={false}
                                value={props.client.ETC?.Title}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.ETC?.Name ? props.client.ETC.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.ETC?.Note}
                                disabled={true}
                                rows={'auto'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.ETC?.createdAt ? YyyyMmDdHhMm(props.client.ETC.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
        </Grid>
        </>
    );
};

export default BasicView;