import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable } from 'components/Items/Table';
import { SearchForm, SelectForm } from 'components/Items/Form';
import { ConfirmModal, FormModal } from 'components/Items/Modal';
import { ScheduleKindKo, ServiceKo, YyyyMmDd } from 'components/Function/ChangeText';

const ScheduleDetailView = (props) => {
    const { scheduleNo } = useParams();
    const navigate = useNavigate();
    const schedule = props.schedule;
    const [openScheduleDeleteModal, setOpenScheduleDeleteModal] = useState(false);
    return (
        <>
        <DetailContainer
            title='스케줄 정보'
            icon={props.user._id === schedule.Counselor_id._id || props.user.Status === 1 || props.user.Status === 3 ? [ { type: 'delete', handleClick: () => setOpenScheduleDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/schedule/${scheduleNo}/update`) } ]: null}
        >
            {schedule.Kind.State === 1 ?
            <DataBox
                datas={[
                    { key: '상담자', value: schedule.Counselor_id.Name },
                    { key: '내담자', value: schedule.Client_id.Name_decrypt ? `${schedule.Client_id.Name_decrypt} (${schedule.Client_id.NickName})` : schedule.Client_id.NickName },
                    { key: '상담 종류', value: ScheduleKindKo(schedule.Kind.Detail) },
                    { key: '상담 일', value: YyyyMmDd(schedule.StartDate) },
                    { key: '상담 시작 시간', value: schedule.StartTime },
                    { key: '상담 종료 시간', value: schedule.EndTime },
                    { key: '상담 방식', value: ServiceKo(schedule.Service) },
                    { key: '상담실', value: schedule.Room_id ? schedule.Room_id.Name : '-' },
                    { key: '상담비', value: schedule.IsFee ? `O / ${schedule.Fee ? schedule.Fee.toLocaleString() : ''}` : `X / ${schedule.Fee ? schedule.Fee.toLocaleString() : ''}` },
                    { key: '반복', value: schedule.Repeat.State ? `${schedule.Repeat.Days}일 / ${schedule.Repeat.Count}회` : '-' },
                    { key: '카카오톡 전송', value: schedule.SendKakao ? `O` : 'X' }
                ]}
            />:
            <DataBox
                datas={[
                    { key: '이름', value: schedule.Counselor_id.Name },
                    { key: '일정 종류', value: ScheduleKindKo(schedule.Kind.Detail) },
                    { key: '일정 일', value: YyyyMmDd(schedule.StartDate) },
                    { key: '일정 시작 시간', value: schedule.StartTime },
                    { key: '일정 종료 시간', value: schedule.EndTime },
                    { key: '상담실', value: schedule.Room_id ? schedule.Room_id.Name : '-' }
                ]}
            />}
            <Grid
                container
                style={{
                    borderRadius: '5px',
                    border: '1px solid #C0C0C0',
                    marginTop: '8px'
                }}
                alignItems={'stretch'}
            >
                <Grid
                    item 
                    xs={4} sm={3} md={1.5} 
                    style={{ 
                        backgroundColor: '#D9D9D9', 
                        textAlign: 'center', 
                        fontWeight: 'bold', 
                        borderRadius: '5px 0px 0px 5px', 
                        padding: '4px 16px', 
                        overflow: 'hidden', 
                        whiteSpace: 'nowrap', 
                        textOverflow: 'ellipsis'
                    }}
                >
                    메모
                </Grid>
                <Grid 
                    item
                    xs={8} sm={9} md={10.5}
                    style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        padding: '4px 16px'
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: schedule.Memo ? schedule.Memo.replaceAll('\n', '<br/>') : 'ㅤ' }} />
                </Grid>
            </Grid>
        </DetailContainer>
        <ConfirmModal
            title='스케줄 삭제'
            icon='error'
            open={openScheduleDeleteModal}
            handleClose={() => setOpenScheduleDeleteModal(false)}
            handleClickSubmit={props.handleClickDeleteBtn}
            submitLabel='삭제'
        >
            스케줄 정보를 삭제 하시겠습니까? 스케줄 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        </>
    );
};

export default ScheduleDetailView;