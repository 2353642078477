import React from 'react';
import { LoadingButton } from '@mui/lab';

/**
 * 
 * @param {string} label 
 * @param {boolean} loading
 * @param {boolean} disabled
 * @param {function} handleClick
 * @returns 
 */
const LoginSubmitButton = (props) => {
    return(
        <LoadingButton
            variant='contained'
            disableElevation
            fullWidth={true}
            size='large'
            style={{ borderRadius: '10px', marginBottom: '10px', height: '44px', fontWeight: 'bold', fontSize: '16px' }}
            loading={props.loading}
            disabled={props.disabled}
            onClick={props.handleClick}
        >
            {props.label}
        </LoadingButton>
    );
};

export default LoginSubmitButton;