import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ClientDetailView } from 'components/User/Client';
import { YyyyMmDd, ScheduleKindKo, ServiceKo, SessionStateKo, GoalActiveKo, GoalPeriodKo, YyMmDd } from 'components/Function/ChangeText';

const ClientDetailContainer = (props) => {
    const { clientNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState({
        NickName: '', // 내담자 ID
        Name: '', // 이름
        Email: '', // 이메일
        Phone: '', // 연락처
        Counselor_id: { _id : '', Name: '' }, // 담당자 _id
        CounselorId: '',
        Risk: '', // 위험도
        Address: '', // 현주소
        Brith: '', // 생년월일
        Gender: '', // 성별
        Marriage: '', // 결혼 유무
        Military: '', // 병역
        Education: '', // 최종학력
        Concern: '', // 현재 고민이나 어려움
        Employment: '', // 직업
        Living: '', // 주거형태
        LivingDetail: '', // 주거형태 상세
        Dependents: '', // 부양가족 수
        Nationality: '', // 국적
        NationalityDetail: '', // 국적 상세
        Religion: '', // 종교
        Medication: '', // 복용약물
        MedicationDetail: '', //복용약물 상세
        Family: [{ // 가족 사항
            Relation: '', // 관계
            Age: '', // 연령
            Name: '', // 이름
            Phone: '' // 연락처
        }],
        Referral: '', // 찾아오게된 경위
        ReferralDetail: '', // 경위 상세
        DesiredService: [], // 찾아온 목적
        Reason: [],  // 상담 받고 싶은 점
        DesiredTest: [], // 받고 싶은 검사
        PriorTreatment: {  // 이전 검사
            PriorExperience: false, // 여부
            When: '', // 언제
            Where: '', // 어디서 
            How: [], // 치료 유형
        },
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []], // 상담 가능 요알, 시간
        Schedule_ids: [],
        Scales: [],
        Category: '',
        Receipt: { // 접수 면접 기록
            Name: '', // 면접자
            Day: '', // 접수면접 날짜
            Time: '', // 접수면접 시간
            Note: '', // 내용
            createdAt: '', // 작성 날짜
        },
        Reference: [{ // 검사 관련 노트
            Title: '', // 제목
            Name: '', // 상담자
            Day: '', // 날짜
            Note: '', // 검사 결과 및 관련 내용
            createdAt: '', // 작성 날짜
        }],
        Report: { // 종결 보고서
            Name: '', // 상담자
            StartDay: '', // 상담 시작 날짜
            EndDay: '', // 상담 종료 날짜
            Count: '', // 상담 횟수
            Note: '', // 내용
            createdAt: '', // 작성 날짜
        },
        Request: { // 의뢰서
            Name: '', // 상담자
            Note: '', // 내용
            createdAt: '', // 작성 날짜
        },
        ETC: { // 기타
            Title: '', // 제목
            Name: '', // 작성자
            Note: '', // 내용
            createdAt: '', // 작성 날짜
        },
    });
    
    // const pages = [
    //     { name: '상담폴더', link: '/client' },
    //     { name: '내담자 상세', link: '' },
    //     { name: client.Name ? `이름: <b>${client.Name}</b>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;내담자ID: <b>${client.NickName}</b>` : `내담자ID: <b>${client.NickName}</b>`, link: '' }
    // ];
    const pages = [
        { name: '상담폴더', link: '/client' },
        { name: '내담자 상세', link: '' }
    ];
    const [session, setSession] = useState({
        Client_id: '',
        Counselor_id: '',
        StartDate: '',
        StartTime: '',
        EndTime: '',
        Service: '',
        State: '',
        Status: 1,
        Room_id: '',
        Kind: { State: 1, Detail: '' },
        Repeat: { State: false, Count: '', Days: '' },
        IsFee: false,
        Fee: '',
        Memo: ''
    });
    const [selectSession, setSelectSession] = useState({
        Client_id: '',
        Counselor_id: '',
        StartDate: '',
        StartTime: '',
        EndTime: '',
        Service: '',
        State: '',
        Status: 1,
        Room_id: '',
        Kind: { State: 1, Detail: '' },
        Repeat: { State: false, Count: '', Days: '' },
        IsFee: false,
        Fee: '',
        Memo: ''
    });
    const [sessions, setSessions] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [scales, setScales] = useState([]);
    const [clientScales, setClientScales] = useState([]);
    const [send, setSend] = useState({
        Email: false,
        Kakao: false
    });
    const [goals, setGoals] = useState([]);
    const [goal, setGoal] = useState({});
    const [selectGoal, setSelectGoal] = useState({});
    const [point, setPoint] = useState({});
    const [user, setUser] = useState({});

    const handleClickStateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        await axios.patch(`/api/client/state/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickScaleBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({ Scales: clientScales });
        await axios.patch(`/api/scale/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                loadData();
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickDeleteScaleBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/scale/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                loadData();
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickBasicBtn = async(Scale_id) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({ Scale_id: Scale_id });
        await axios.patch(`/api/scale/basic/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                loadData();
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickBasicDeleteBtn = async(Scale_id) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/scale/basic/${Scale_id}/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                loadData();
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };
    
    const handleClickUrlSendBtn = async(scheduleNo, itemid) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const label = scheduleNo === 'basic' ? client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3 && item.Scale_id._id === itemid))[0].Scale_id.Label : client.Scales.filter((a) => (a.Scale_id.ScaleNo === itemid))[0].Scale_id.Label;
        const body = JSON.stringify({
            Email: client.Email_decrypt,
            Phone: client.Phone_decrypt,
            // Label: scheduleNo === 'basic' ? client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3 && item.Scale_id._id === itemid))[0].Scale_id.Label : `${client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3))[0].Scale_id.Label} ${client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3)).length > 1 ? ` 외 ${client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3)).length-1}개` : ''}`,
            // Link: scheduleNo === 'basic' ? `${window.location.origin}/scale/${clientNo}/basic/${itemid}` : `${window.location.origin}/scale/${clientNo}/${scheduleNo}`
            Label: label,
            Link: scheduleNo === 'basic' ? `${window.location.origin}/scale/${clientNo}/basic/${itemid}` : `${window.location.origin}/scale/${clientNo}/${scheduleNo}/${itemid}`,
            basic: scheduleNo === 'basic' ? true : false
        });
        if(send.Email){
            await axios.post(`/api/client/email/${cookies.LoginKey}`, body, config)
            .then(async(res) => {
                if(!res.data.ErrorMessage){
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    const title = `내담자 ${client.Name_decrypt ? client.Name_decrypt : client.NickName}에게 ${label} 링크 전송`;
                    const content = `이메일(${client.Email_decrypt})로 링크가 전송되었습니다.`;
                    await axios.post(`/api/message/${cookies.LoginKey}`, { Reciver_id: client.Counselor_id._id, Title: title, Content: content }, config)
                    .then(() => {})
                    .catch(() => enqueueSnackbar('Network Error!', { variant: 'error' }));
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }
        if(send.Kakao){
            await axios.post(`/api/client/kakao/${cookies.LoginKey}`, body, config)
            .then(async(res) => {
                if(!res.data.ErrorMessage){
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    const title = `내담자 ${client.Name_decrypt ? client.Name_decrypt : client.NickName}에게 ${label} 링크 전송`;
                    const content = `카카오톡(${client.Phone_decrypt})으로 링크가 전송되었습니다.`;
                    await axios.post(`/api/message/${cookies.LoginKey}`, { Reciver_id: client.Counselor_id._id, Title: title, Content: content }, config)
                    .then(() => {})
                    .catch(() => enqueueSnackbar('Network Error!', { variant: 'error' }));
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }
    };

    const handleClickSessionSave = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(selectSession);
        await axios.put(`/api/manager/session/detail/${selectSession.ScheduleNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                let changeClient = { ...client };
                for(let i = 0; i < changeClient.Schedule_ids.length; i++){
                    if(changeClient.Schedule_ids[i].ScheduleNo === selectSession.ScheduleNo){
                        changeClient.Schedule_ids[i].Note = selectSession.Note;
                        changeClient.Schedule_ids[i].GAF = selectSession.GAF;
                        changeClient.Schedule_ids[i].Risk = selectSession.Risk;
                        changeClient.Schedule_ids[i].State = selectSession.State;
                        changeClient.Schedule_ids[i].StateValue = SessionStateKo(selectSession.State);
                        break;
                    }
                }
                setClient(changeClient);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickCreateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(session);
        await axios.post(`/api/manager/session/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickUpdateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(selectSession);
        await axios.put(`/api/manager/session/${selectSession.ScheduleNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/session/${selectSession.ScheduleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickCreateGoalBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(goal);
        await axios.post(`/api/goal/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    let changeGoals = [...goals];
                    let loadGoal = { ...res.data.GoalData };
                    loadGoal.ActiveValue = GoalActiveKo(loadGoal.Active);
                    loadGoal.PeriodValue = GoalPeriodKo(loadGoal.Period);
                    loadGoal.StartEnd = ` - `;
                    changeGoals.push(loadGoal);
                    setGoals(changeGoals);
                    setGoal({});
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickUpdateGoalBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(selectGoal);
        await axios.put(`/api/goal/${selectGoal.GoalNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    setSelectGoal({});
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickGoalPointsBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        let changePoints = { ...selectGoal };
        changePoints.Points.push(point);
        changePoints.Points = changePoints.Points.sort((a, b) => (new Date(a.Date) - new Date(b.Date)));
        if(changePoints.Points.length === 0){
            changePoints.StartEnd = ` - `;
        }else if(changePoints.Points.length === 1){
            changePoints.StartEnd = `${changePoints.Points[0].Point} - `;
        }else{
            changePoints.StartEnd = `${changePoints.Points[0].Point} - ${changePoints.Points[changePoints.Points.length - 1].Point}`;
        }
        const body = JSON.stringify({ Points: selectGoal.Points });
        await axios.patch(`/api/goal/${selectGoal.GoalNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    setPoint({});
                    setSelectGoal(changePoints);
                    let changeGoals = [...goals];
                    for(let i = 0; i < changeGoals.length; i++){
                        if(changeGoals[i].Points.length === 0){
                            changeGoals[i].StartEnd = ` - `;
                        }else if(changeGoals[i].Points.length === 1){
                            changeGoals[i].StartEnd = `${changeGoals[i].Points[0].Point} - `;
                        }else{
                            changeGoals[i].StartEnd = `${changeGoals[i].Points[0].Point} - ${changeGoals[i].Points[changeGoals[i].Points.length - 1].Point}`;
                        }
                        if(changeGoals[i].GoalNo === changePoints.GoalNo){
                            changeGoals[i].Points = changePoints.Points;
                        }
                    }
                    setGoals(changeGoals);
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickDeleteGoalBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/goal/${selectGoal.GoalNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    setSelectGoal({});
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickReceipt = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        await axios.put(`/api/client/receipt/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            let changeClient = { ...client };
            changeClient.Receipt.Name = res.data.Name;
            changeClient.Receipt.createdAt = res.data.createdAt;
            setClient(changeClient);
            enqueueSnackbar(res.data.Message, { variant: 'success' });
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickReference = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        await axios.put(`/api/client/reference/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            let changeClient = { ...client };
            for(let i = 0; i < changeClient.Reference.length; i++){
                if(changeClient.Reference[i].update){
                    changeClient.Reference[i].Name = res.data.Name;
                    changeClient.Reference[i].createdAt = res.data.createdAt;
                    changeClient.Reference[i].update = false;
                }
            }
            setClient(changeClient);
            enqueueSnackbar(res.data.Message, { variant: 'success' });
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickReport = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        await axios.put(`/api/client/report/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            let changeClient = { ...client };
            changeClient.Report.Name = res.data.Name;
            changeClient.Report.createdAt = res.data.createdAt;
            setClient(changeClient);
            enqueueSnackbar(res.data.Message, { variant: 'success' });
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickRequest = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        await axios.put(`/api/client/request/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            let changeClient = { ...client };
            changeClient.Request.Name = res.data.Name;
            changeClient.Request.createdAt = res.data.createdAt;
            setClient(changeClient);
            enqueueSnackbar(res.data.Message, { variant: 'success' });
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickETC = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(client);
        await axios.put(`/api/client/etc/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            let changeClient = { ...client };
            changeClient.ETC.Name = res.data.Name;
            changeClient.ETC.createdAt = res.data.createdAt;
            setClient(changeClient);
            enqueueSnackbar(res.data.Message, { variant: 'success' });
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/client/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setUser(res.data.User_id);
                let loadClient = res.data.ClientData;
                loadClient.Email = loadClient.Email_decrypt;
                loadClient.Phone = loadClient.Phone_decrypt;
                loadClient.Name = loadClient.Name_decrypt;
                loadClient.Available = loadClient.Available.length ? loadClient.Available : [[], [], [], [], [], [], []];
                loadClient.AvailableDay = [];
                loadClient.CreatedAtValue = YyMmDd(loadClient.createdAt);
                for(let i = 0; i < loadClient.Available.length; i++){
                    if(loadClient.Available[i].length){
                        loadClient.AvailableDay.push((i+1).toString());
                        for(let j = 0; j < loadClient.Available[i].length; j++){
                            loadClient.Available[i][j] = loadClient.Available[i][j].toString();
                        }
                    }
                }
                loadClient.Scales = loadClient.Scales.filter((item) => (item.Scale_id?.State === 1));
                loadClient.Schedule_ids = loadClient.Schedule_ids.filter((item) => (item.Counselor_id._id === res.data.User_id._id));
                for(let i = 0; i < loadClient.Schedule_ids.length; i++){
                    loadClient.Schedule_ids[i].No = i + 1;
                    loadClient.Schedule_ids[i].ClientId = loadClient.Schedule_ids[i].Client_id._id;
                    loadClient.Schedule_ids[i].CounselorId = loadClient.Schedule_ids[i].Counselor_id._id;
                    loadClient.Schedule_ids[i].CounselorName = loadClient.Schedule_ids[i].Counselor_id.Name;
                    loadClient.Schedule_ids[i].StartDateValue = YyyyMmDd(loadClient.Schedule_ids[i].StartDate);
                    loadClient.Schedule_ids[i].StartDateTimeValue = `${YyyyMmDd(loadClient.Schedule_ids[i].StartDate)} ${loadClient.Schedule_ids[i].StartTime}`;
                    loadClient.Schedule_ids[i].KindDetailValue = ScheduleKindKo(loadClient.Schedule_ids[i].Kind.Detail);
                    loadClient.Schedule_ids[i].ServiceValue = ServiceKo(loadClient.Schedule_ids[i].Service);
                    loadClient.Schedule_ids[i].StateValue = SessionStateKo(loadClient.Schedule_ids[i].State);
                    if(loadClient.Schedule_ids[i].ScaleAnswer.length){
                        for(let j = 0; j < loadClient.Schedule_ids[i].ScaleAnswer.length; j++){
                            if(loadClient.Schedule_ids[i].ScaleAnswer[j].Answer.length){
                                let tumpScale = loadClient.Scales.filter((item) => (item.Scale_id?._id === loadClient.Schedule_ids[i].ScaleAnswer[j]?.Scale_id))[0]?.Scale_id;
                                if(tumpScale){
                                    if(tumpScale.CalScore){
                                        let tumpPoint = 0;
                                        let maxPoint = 0;
                                        let subScalePoint = {};
                                        for(let k = 0; k < loadClient.Schedule_ids[i].ScaleAnswer[j].Answer.length; k++){
                                            for(let k2 = 0; k2 < loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer.length; k2++){
                                                let tumpItem = tumpScale.Item_ids.filter((item) => (item._id === loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Item_id))[0];
                                                if( tumpItem !== undefined ) {
                                                    if(tumpItem.SubScale){
                                                        if(subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`]){
                                                            // if(tumpScale.Negative){
                                                            //     subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] -= tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                            // }else{
                                                                subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] += tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                            // }
                                                        }else{
                                                            // if(tumpScale.Negative){
                                                            //     subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] = tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]]*(-1) : 0;   
                                                            // }else{
                                                                subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] = tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                            // }
                                                        }
                                                    }
                                                    // if(tumpScale.Negative){
                                                    //     tumpPoint -= tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                    //     maxPoint -= Math.max.apply(null, tumpItem.Points) ? Math.max.apply(null, tumpItem.Points) : 0;
                                                    // }else{
                                                        tumpPoint += tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                        maxPoint += Math.max.apply(null, tumpItem.Points) ? Math.max.apply(null, tumpItem.Points) : 0;
                                                    // }
                                                }
                                            }
                                        }
                                        loadClient.Schedule_ids[i][`${loadClient.Schedule_ids[i].ScaleAnswer[j].Scale_id}`] = tumpPoint;
                                        loadClient.Schedule_ids[i][`${loadClient.Schedule_ids[i].ScaleAnswer[j].Scale_id}Max`] = maxPoint;
                                        Object.assign(loadClient.Schedule_ids[i], subScalePoint);
                                    }else{
                                        loadClient.Schedule_ids[i][`${loadClient.Schedule_ids[i].ScaleAnswer[j].Scale_id}`] = 'O';
                                    }
                                }
                            }
                        }
                    }
                }
                setSessions(loadClient.Schedule_ids);
                setClient(loadClient);
                setSession({
                    Client_id: loadClient._id,
                    Counselor_id: loadClient.Counselor_id ? loadClient.Counselor_id._id : '',
                    StartDate: '',
                    StartTime: '',
                    EndTime: '',
                    Service: '',
                    State: '',
                    Status: 1,
                    Room_id: '',
                    Kind: { State: 1, Detail: '' },
                    Repeat: { State: false, Count: '', Days: '' },
                    Memo: ''
                });
                setSelectSession({
                    Client_id: loadClient._id,
                    Counselor_id: loadClient.Counselor_id ? loadClient.Counselor_id._id : '',
                    StartDate: '',
                    StartTime: '',
                    EndTime: '',
                    Service: '',
                    State: '',
                    Status: 1,
                    Room_id: '',
                    Kind: { State: 1, Detail: '' },
                    Repeat: { State: false, Count: '', Days: '' },
                    Memo: ''
                });
                let loadClientScales = [];
                for(let i = 0; i < loadClient.Scales.length; i++){
                    loadClientScales.push({ Scale_id: loadClient.Scales[i].Scale_id._id, Primary: loadClient.Scales[i].Primary, Basic: loadClient.Scales[i].Basic });
                }
                setClientScales(loadClientScales);
                setTimeout(() => {
                    setLoading(false);
                }, [850]);
            }else{
                setLoading(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setLoading(false);
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
        await axios.get(`/api/manager/room/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadRooms = [...res.data.RoomDatas];
                loadRooms = loadRooms.filter((item) => (item.State === 1));
                for(let i = 0; i < loadRooms.length; i++){
                    loadRooms[i].value = loadRooms[i]._id;
                    loadRooms[i].label = loadRooms[i].Name;
                    loadRooms[i].disabled = false;
                }
                setRooms(loadRooms);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/scale/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadScales = [...res.data.ScaleDatas];
                loadScales = loadScales.filter((item) => (item.State === 1));
                for(let i = 0; i < loadScales.length; i++){
                    loadScales[i].value = loadScales[i]._id;
                    loadScales[i].label = loadScales[i].Label;
                }
                setScales(loadScales);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/goal/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadGoals = [...res.data.GoalDatas];
                for(let i = 0; i < loadGoals.length; i++){
                    loadGoals[i].No = i + 1;
                    loadGoals[i].ActiveValue = GoalActiveKo(loadGoals[i].Active);
                    loadGoals[i].PeriodValue = GoalPeriodKo(loadGoals[i].Period);
                    if(loadGoals[i].Points.length === 0){
                        loadGoals[i].StartEnd = ` - `;
                    }else if(loadGoals[i].Points.length === 1){
                        loadGoals[i].StartEnd = `${loadGoals[i].Points[0].Point} - `;
                    }else{
                        loadGoals[i].StartEnd = `${loadGoals[i].Points[0].Point} - ${loadGoals[i].Points[loadGoals[i].Points.length - 1].Point}`;
                    }
                }
                setGoals(loadGoals);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, [enqueueSnackbar, cookies.LoginKey, clientNo, setClient, setSessions, setRooms, setScales, setClientScales, setGoals]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ClientDetailView
                user={user} 
                client={client}
                setClient={setClient}
                handleClickStateBtn={handleClickStateBtn}
                session={session}
                setSession={setSession}
                selectSession={selectSession}
                setSelectSession={setSelectSession}
                sessions={sessions}
                rooms={rooms}
                setRooms={setRooms}
                scales={scales}
                clientScales={clientScales}
                setClientScales={setClientScales}
                handleClickScaleBtn={handleClickScaleBtn}
                handleClickDeleteScaleBtn={handleClickDeleteScaleBtn}
                handleClickBasicBtn={handleClickBasicBtn}
                handleClickBasicDeleteBtn={handleClickBasicDeleteBtn}
                handleClickUrlSendBtn={handleClickUrlSendBtn}
                send={send}
                setSend={setSend}
                handleClickSessionSave={handleClickSessionSave}
                handleClickCreateBtn={handleClickCreateBtn}
                handleClickUpdateBtn={handleClickUpdateBtn}
                handleClickDeleteBtn={handleClickDeleteBtn}
                goal={goal}
                setGoal={setGoal}
                goals={goals}
                setGoals={setGoals}
                selectGoal={selectGoal}
                setSelectGoal={setSelectGoal}
                handleClickCreateGoalBtn={handleClickCreateGoalBtn}
                handleClickUpdateGoalBtn={handleClickUpdateGoalBtn}
                handleClickDeleteGoalBtn={handleClickDeleteGoalBtn}
                handleClickGoalPointsBtn={handleClickGoalPointsBtn}
                point={point}
                setPoint={setPoint}
                handleClickReceipt={handleClickReceipt}
                handleClickReference={handleClickReference}
                handleClickReport={handleClickReport}
                handleClickRequest={handleClickRequest}
                handleClickETC={handleClickETC}
                loadData={loadData}
                loading={loading}
                setLoading={setLoading}
            />
        </DashboardLayout>
    );
};

export default ClientDetailContainer;