import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { NewLoginView } from 'components';

const LoginContainer = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [, setCookies] = useCookies(['LoginKey']);
    const [user, setUser] = useState({
        Email: '',
        Password: ''
    });
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({ Name: '', Email: '', Phone: '', Origin: window.location.origin ? window.location.origin : 'https://maumsensor.com' });
    const [searchEmail, setSearchEmail] = useState('');
    const [openPasswordFind, setOpenPasswordFind] = useState(false);
    const [searching, setSearching] = useState(false)

    const handleClickLoginBtn = async() => {
        setLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(user);
        await axios.post(`/api/login`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                if(res.data.State === 1){
                    setCookies('LoginKey', res.data.LoginKey, { path: '/' });
                    setCookies('Name', res.data.Name, { path: '/' });
                    setCookies('UserNo', res.data.UserNo, { path: '/' });
                    setCookies('Status', res.data.Status, { path: '/' });
                    if(res.data.Status === 1){
                        setTimeout(() => {
                            navigate(`/client`);
                        }, [1200]);
                    }else if(res.data.Status === 2){
                        setTimeout(() => {
                            navigate(`/client`);
                        }, [1200]);
                    }else if(res.data.Status === 3){
                        setTimeout(() => {
                            navigate(`/schedule`);
                        }, [1200]);
                    }else if(res.data.Status === 5){
                        setTimeout(() => {
                            navigate(`/admin/user`);
                        }, [1200]);
                    }
                }else if(res.data.State === 2){
                    enqueueSnackbar('관리자의 확인이 필요합니다.', {  variant: 'info' });
                }else if(res.data.State === 3){
                    enqueueSnackbar('사용이 정지된 계정입니다.', { variant: 'error' });
                }else{
                    enqueueSnackbar('로그인 에러. 관리자에게 문의해주세요.', { variant: 'error' });
                }
            }else{
                setLoading(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }            
        })
        .catch((err) => {
            setLoading(false);
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickSearchEmail = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(search);
        await axios.post(`/api/user/email`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setSearchEmail(res.data.Email);
                setSearch({ Phone: '', Name: '', Email: '', Origin: window.location.origin });
            }else{
                setSearch({ Phone: '', Name: '', Email: '', Origin: window.location.origin });
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            setSearch({ Phone: '', Name: '', Email: '', Origin: window.location.origin });
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickSearchPassword = async() => {
        setSearching(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(search);
        await axios.post(`/api/user/password`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                setTimeout(() => {
                    setSearching(false);
                    setSearch({ Phone: '', Name: '', Email: '', Origin: window.location.origin });
                    setOpenPasswordFind(false);
                }, [500]);
            }else{
                setSearch({ Phone: '', Name: '', Email: '', Origin: window.location.origin });
                setSearching(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            setSearch({ Phone: '', Name: '', Email: '', Origin: window.location.origin });
            setSearching(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    return(
        <NewLoginView 
            user={user}
            setUser={setUser}
            loading={loading}
            handleClickLoginBtn={handleClickLoginBtn}
            search={search}
            setSearch={setSearch}
            searchEmail={searchEmail}
            setSearchEmail={setSearchEmail}
            handleClickSearchEmail={handleClickSearchEmail}
            handleClickSearchPassword={handleClickSearchPassword}
            openPasswordFind={openPasswordFind}
            setOpenPasswordFind={setOpenPasswordFind}
            searching={searching}
            setSearching={setSearching}
        />
    );
};

export default LoginContainer;