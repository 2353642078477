import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, FormControl, Checkbox, FormGroup, FormControlLabel, FormHelperText, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import { TextForm, TimeForm, DateForm, NumberForm } from 'components/Items/Form';
import { SmallCancelButton, SmallSubmitButton } from 'components/Items/Button';
import { ItemShortForm, ItemMultipleChoice, ItemLikert, ItemShortFormOther } from 'components/Items/View';
import { FormModal, ConfirmModal } from 'components/Items/Modal';
import { DetailContainer } from 'components/Items/Container';
import { YyyyMmDdHhMm } from 'components/Function/ChangeText';

const BasicView = (props) => {
    const { clientNo } = useParams();
    const basicScale = props.basicScale;
    const [openUrlSendModal, setOpenUrlSendModal] = useState(false);
    const [openResetModal, setOpenResetModal] = useState(false);
        
    const handleClickBasicScale = (id) => {
        window.open(`${window.location.origin}/scale/${clientNo}/basic/${id}/loginKey`);
    };
    const handleCloseUrlSend = () => {
        setOpenUrlSendModal(false);
        props.setSend({ Email: false, Kakao: false });
    };
    const handleClickReset = (id) => {
        setItemId(id);
        setOpenResetModal(true);
    };
    const handleClickURL = (id) => {
        setItemId(id);
        setOpenUrlSendModal(true)
    }
    const handleChangeReceipt = (e, key) => {
        let changeReceipt = { ...props.client };
        changeReceipt.Receipt = changeReceipt.Receipt ? changeReceipt.Receipt : { };
        changeReceipt.Receipt[key] = e.target.value;
        changeReceipt.Receipt.update = true;
        props.setClient(changeReceipt);
    };

    const handleChangeReference = (e, i, key) => {
        let changeReference = { ...props.client };
        if(!changeReference.Reference.length){
            changeReference.Reference.push({ Title: '', None: '', Day: '' });
        }
        changeReference.Reference[i][key] = e.target.value;
        changeReference.Reference[i].update = true;
        props.setClient(changeReference);
    };

    const handleChangeReport = (e, key) => {
        let changeReport = { ...props.client };
        changeReport.Report = changeReport.Report ? changeReport.Report : { };
        changeReport.Report[key] = e.target.value;
        changeReport.Report.update = true;
        props.setClient(changeReport);
    };

    const handleChangeRequest = (e, key) => {
        let changeRequest = { ...props.client };
        changeRequest.Request = changeRequest.Request ? changeRequest.Request : { };
        changeRequest.Request[key] = e.target.value;
        changeRequest.Request.update = true;
        props.setClient(changeRequest);
    };

    const handleChangeETC = (e, key) => {
        let changeETC = { ...props.client };
        changeETC.ETC = changeETC.ETC ? changeETC.ETC : { };
        changeETC.ETC[key] = e.target.value;
        changeETC.ETC.update = true;
        props.setClient(changeETC);
    };

    const handleClickPlus = () => {
        let changeReference = { ...props.client };
        if(changeReference.Reference.length){
            changeReference.Reference.push({ Title: '', None: '', Day: '' });
        }else{
            changeReference.Reference.push({ Title: '', None: '', Day: '' });
            changeReference.Reference.push({ Title: '', None: '', Day: '' });
        }
        props.setClient(changeReference);
    };

    const handleClickMinus = () => {
        let changeReference = { ...props.client };
        changeReference.Reference.pop();
        props.setClient(changeReference);
    };

    const [itemId, setItemId] = useState('');

    const option1 = [13, 26, 39, 49, 2, 19, 29, 50, 12, 24, 35, 48, 9, 25, 8, 20, 36, 10, 23, 37, 47, 6, 22 ,32, 44, 3, 31, 5, 21, 40, 43, 14, 17, 27, 46, 1, 15, 34, 38, 7, 33, 45, 4, 11, 16, 18, 28, 30, 41, 42];
    const subScale1 = ['① 어머니에 대한 태도', '① 어머니에 대한 태도', '① 어머니에 대한 태도', '① 어머니에 대한 태도',
                        '② 아버지에 대한 태도', '② 아버지에 대한 태도', '② 아버지에 대한 태도', '② 아버지에 대한 태도',
                        '③ 가족에 대한 태도', '③ 가족에 대한 태도', '③ 가족에 대한 태도', '③ 가족에 대한 태도',
                        '④ 여성에 대한 태도', '④ 여성에 대한 태도',
                        '⑤ 남성에 대한 태도', '⑤ 남성에 대한 태도', '⑤ 남성에 대한 태도',
                        '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도', '⑥ 이성, 결혼에 대한 태도',
                        '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도', '⑦ 친구나 친지에 대한 태도',
                        '⑧ 권위자에 대한 태도', '⑧ 권위자에 대한 태도',
                        '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도', '⑨ 두려움에 대한 태도',
                        '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도', '⑩ 죄책감에 대한 태도',
                        '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도', '⑪ 자신의 능력에 대한 태도',
                        '⑫ 과거에 대한 태도', '⑫ 과거에 대한 태도', '⑫ 과거에 대한 태도',
                        '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도', '⑬ 미래에 대한 태도',
                        '⑭ 목표에 대한 태도', '⑭ 목표에 대한 태도', '⑭ 목표에 대한 태도'];
    const option2 = [15, 23, 14, 27, 1, 8, 11, 12, 16, 18, 22, 34, 13, 28, 36, 9, 17, 31, 37, 2, 6, 10, 35, 4, 20, 21,24, 25, 30, 32, 33, 29, 7, 38, 3, 5, 19, 26];
    const subScale2 = ['① 어머니에 대한 태도', '① 어머니에 대한 태도',
                        '② 아버지에 대한 태도', '② 아버지에 대한 태도',
                        '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계', '③ 대인 지각 및 관계',
                        '④ 학교생활에 대한 태도', '④ 학교생활에 대한 태도', '④ 학교생활에 대한 태도',
                        '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도', '⑤ 부정적인 것에 대한 태도',
                        '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도', '⑥ 긍정적인 것에 대한 태도',
                        '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', '⑦ 자신의 능력에 대한 태도', 
                        '⑧ 과거에 대한 태도',
                        '⑨ 미래에 대한 태도', '⑨ 미래에 대한 태도',
                        '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도', '⑩ 목표에 대한 태도'];
    let collection = [];
    
    return(
        <>
        <Grid container>
            {false ? 
            <Grid item xs={12}>
                {/* <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <h4>기본 서류</h4>
                    </Grid>
                    <Grid item>
                        <SmallCancelButton
                            label='초기화'
                            handleClick={() => setOpenResetModal(true)}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '8px'}}>
                    <Grid container item xs={8} alignItems={'center'}>
                        <Grid item>
                            <b>{basicScale.Scale_id.Label}</b>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} spacing={1}  justifyContent={'flex-end'} container>
                        <Grid item>
                            <SmallCancelButton
                                label='URL'
                                handleClick={() => setOpenUrlSendModal(true)}
                            />
                        </Grid>
                        <Grid item>
                            <SmallCancelButton
                                label='입력'
                                handleClick={handleClickBasicScale}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid style={{ marginBottom : '16px' }}>
                            {basicScale.Scale_id.Detail}
                        </Grid>
                        {basicScale.Scale_id._id === '653f90eba149184a6bc2b8b4' ?
                        basicScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((_, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            <ItemShortFormOther 
                                item={basicScale.Scale_id?.Item_ids[`${option1[j]-1}`]} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={j === 0 || subScale1[j] !== subScale1[j-1]}
                                subScales={subScale1}
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === basicScale.Scale_id.Item_ids[`${option1[j]-1}`]?._id))[0]?.Answer}
                            />
                        </Grid>
                        ))
                        :
                        basicScale.Scale_id._id === '653fc2bba149184a6bc2e2f2' ?
                        basicScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((_, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            <ItemShortFormOther 
                                item={basicScale.Scale_id?.Item_ids[`${option2[j]-1}`]} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={j === 0 || subScale2[j] !== subScale2[j-1]}
                                subScales={subScale2}
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === basicScale.Scale_id.Item_ids[`${option1[j]-1}`]?._id))[0]?.Answer}
                            />
                        </Grid>
                        ))
                        :
                        basicScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                        <Grid key={j} style={{ marginBottom: '8px' }}>
                            {q.ItemKind === 1 ?
                            <ItemShortForm 
                                item={q} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== basicScale.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            q.ItemKind === 2 ?
                            <ItemMultipleChoice 
                                item={q}
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== basicScale.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            q.ItemKind === 3 ?
                            <ItemLikert 
                                item={q} 
                                no={j+1} 
                                scale={basicScale.Scale_id} 
                                subScale={
                                    q.SubScale && (j === 0 || q.SubScale !== basicScale.Scale_id.Item_ids[j-1].SubScale)
                                } 
                                lastItem={basicScale.Scale_id.Item_ids.length === j+1}
                                answer={props.client.BasicAnswer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                            /> :
                            null}
                        </Grid>
                        ))}
                    </Grid>
                </Grid> */}
            </Grid>:
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <h4 style={{ width: '380px', borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>기본 서류 선택</h4>
                    </Grid>
                </Grid>
                {/* <DetailContainer
                    title='기본 서류 선택'
                    collapse={0}
                    shadow='none'
                    h4={true}
                > */}
                {props.scales.map((item, i) => (
                // item.ScaleKind === 3 ?
                <Grid container key={i} style={{ marginBottom: '8px'}}>
                    <Grid item xs={8}>
                        <Grid container item xs={12} alignItems={'center'}>
                            <Grid item>
                                <b>{item.Label}</b>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid style={{ marginBottom : '16px' }}>
                                {item.Detail}
                            </Grid>
                        </Grid>
                    </Grid>
                    {basicScale.filter((scale) => (scale.Scale_id._id === item._id)).length?
                    <Grid item xs={12}>
                        <Grid container style={{ marginBottom: '8px'}}>
                            {/* <Grid item>
                                <h4>기본 서류</h4>
                            </Grid> */}
                            <Grid item xs={12} spacing={1} justifyContent={'flex-end'} container>
                                <SmallCancelButton
                                    label='초기화'
                                    handleClick={() => handleClickReset(item._id)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginBottom: '8px'}}>
                            {/* <Grid container item xs={8} alignItems={'center'}>
                                <Grid item>
                                    <b>{item.Label}</b>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={12} spacing={1} justifyContent={'flex-end'} container>
                                <Grid item>
                                    <SmallCancelButton
                                        label='URL 전송'
                                        handleClick={() => handleClickURL(item._id)}
                                    />
                                </Grid>
                                <Grid item>
                                    <SmallCancelButton
                                        label='직접 입력'
                                        handleClick={() => handleClickBasicScale(item._id)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Grid style={{ marginBottom : '16px' }}>
                                {item.Detail}
                            </Grid> */}
                            {item._id === '653f90eba149184a6bc2b8b4' ?
                            basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((abc, j) => (
                            <Grid key={j} style={{ marginBottom: '8px' }}>
                                <ItemShortFormOther 
                                    item={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids[`${option1[j]-1}`]} 
                                    no={j+1} 
                                    scale={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id} 
                                    subScale={j === 0 || subScale1[j] !== subScale1[j-1]}
                                    subScales={subScale1}
                                    lastItem={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.length === j+1}
                                    answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id?._id === item?._id))[0]?.Answer.filter((a) => (a.Item_id === basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids[`${option1[j]-1}`]?._id))[0]}
                                />
                            </Grid>
                            ))
                            :
                            item._id === '653fc2bba149184a6bc2e2f2' ?
                            basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((_, j) => (
                            <Grid key={j} style={{ marginBottom: '8px' }}>
                                <ItemShortFormOther 
                                    item={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids[`${option2[j]-1}`]} 
                                    no={j+1} 
                                    scale={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id} 
                                    subScale={j === 0 || subScale2[j] !== subScale2[j-1]}
                                    subScales={subScale2}
                                    lastItem={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.length === j+1}
                                    answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id?._id === item._id))[0]?.Answer.filter((a) => (a.Item_id === basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids[`${option1[j]-1}`]?._id))[0]}
                                />
                            </Grid>
                            ))
                            :
                            basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                            <Grid key={j} style={{ marginBottom: '8px' }}>
                                {q.ItemKind === 1 ?
                                <ItemShortForm 
                                    item={q} 
                                    no={j+1} 
                                    scale={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id} 
                                    subScale={
                                        q.SubScale && (j === 0 || q.SubScale !== basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids[j-1].SubScale)
                                    } 
                                    lastItem={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.length === j+1}
                                    // answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id._id === item._id)).filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                    answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id?._id === item._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                /> :
                                q.ItemKind === 2 ?
                                <ItemMultipleChoice 
                                    item={q}
                                    no={j+1} 
                                    scale={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id} 
                                    subScale={
                                        q.SubScale && (j === 0 || q.SubScale !== basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids[j-1].SubScale)
                                    } 
                                    lastItem={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.length === j+1}
                                    // answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id._id === item._id)).filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                    answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id?._id === item._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                /> :
                                q.ItemKind === 3 ?
                                <ItemLikert 
                                    item={q} 
                                    no={j+1} 
                                    scale={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id} 
                                    subScale={
                                        q.SubScale && (j === 0 || q.SubScale !== basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids[j-1].SubScale)
                                    } 
                                    lastItem={basicScale.filter((scale) => (scale.Scale_id._id === item._id))[0].Scale_id.Item_ids.length === j+1}
                                    // answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id._id === item._id)).filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                    answer={props.client.BasicAnswer.filter((scale) => (scale.Scale_id?._id === item._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                /> :
                                null}
                            </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    :
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <SmallSubmitButton
                            label='선택'
                            handleClick={() => props.handleClickBasicBtn(item._id)}
                        />
                    </Grid>}
                </Grid>
                // :null
                ))}
                {/* </DetailContainer> */}
            </Grid>}
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='접수 면접 기록'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Receipt?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextForm
                                label='면접자'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Name ? props.client.Receipt.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateForm
                                label='접수면접 날짜 및 시간'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Day}
                                handleChange={(e) => handleChangeReceipt(e, 'Day')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimeForm
                                label='ㅤ'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Time}
                                handleChange={(e) => handleChangeReceipt(e, 'Time')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.Note}
                                disabled={false}
                                rows={'auto'}
                                handleChange={(e) => handleChangeReceipt(e, 'Note')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Receipt?.createdAt ? YyyyMmDdHhMm(props.client.Receipt.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} spacing={1} justifyContent={'flex-end'} container>
                            <Grid item>
                                <SmallCancelButton
                                    label='PDF'
                                    handleClick={() => window.open(`${window.location.origin}/basic/pdf/${clientNo}/receipt`)}
                                />
                            </Grid>
                            <Grid item>
                                <SmallSubmitButton
                                    label='저장'
                                    handleClick={props.handleClickReceipt}
                                    disabled={!props.client.Receipt?.update || !props.client.Receipt?.Day || !props.client.Receipt.Time}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='검사 관련 노트'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Reference && props.client.Reference.length > 0 ? 'primary' : 'secondary'}
                >
                    {props.client.Reference && props.client.Reference.length > 1 ?
                    props.client.Reference.map((item, i) => (
                    <Grid container spacing={1} justifyContent={'center'}>
                        <Grid item xs={4}>
                            <TextForm
                                label='검사 제목'
                                required={false}
                                error={false}
                                value={item.Title}
                                handleChange={(e) => handleChangeReference(e, i, 'Title')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={item.Name ? item.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateForm
                                label='날짜'
                                required={false}
                                error={false}
                                value={item.Day}
                                handleChange={(e) => handleChangeReference(e, i, 'Day')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='검사 결과 및 관련 내용'
                                required={false}
                                error={false}
                                value={item.Note}
                                disabled={false}
                                rows={'auto'}
                                handleChange={(e) => handleChangeReference(e, i, 'Note')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={item.createdAt ? YyyyMmDdHhMm(item.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                        {props.client.Reference.length !== i+1 ? <Grid item xs={11.5} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', marginBottom: '16px' }} /> : null}
                    </Grid>
                    )):
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextForm
                                label='검사 제목'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Title}
                                handleChange={(e) => handleChangeReference(e, 0, 'Title')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Name ? props.client.Reference[0]?.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateForm
                                label='날짜'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Day}
                                handleChange={(e) => handleChangeReference(e, 0, 'Day')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='검사 결과 및 관련 내용'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.Note}
                                disabled={false}
                                rows={'auto'}
                                handleChange={(e) => handleChangeReference(e, 0, 'Note')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Reference[0]?.createdAt ? YyyyMmDdHhMm(props.client.Reference[0]?.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>}
                    <Grid item xs={12} spacing={1} justifyContent={'space-between'} container>
                        <Grid item xs={6}>
                            <IconButton disabled={props.client.Reference.length === 1 ? true : false} onClick={handleClickMinus}>
                                <Remove />
                            </IconButton>
                            <IconButton onClick={handleClickPlus}>
                                <Add />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} container spacing={1} justifyContent={'flex-end'}>
                            <Grid item>
                                <SmallCancelButton
                                    label='PDF'
                                    handleClick={() => window.open(`${window.location.origin}/basic/pdf/${clientNo}/reference`)}
                                />
                            </Grid>
                            <Grid item>
                                <SmallSubmitButton
                                    label='저장'
                                    handleClick={props.handleClickReference}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='종결 보고서'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Report?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.Report?.Name ? props.client.Report.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateForm
                                label='상담 시작 일'
                                required={false}
                                error={false}
                                value={props.client.Report?.StartDay}
                                handleChange={(e) => handleChangeReport(e, 'StartDay')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateForm
                                label='상담 종료 일'
                                required={false}
                                error={false}
                                value={props.client.Report?.EndDay}
                                handleChange={(e) => handleChangeReport(e, 'EndDay')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.Report?.Note}
                                disabled={false}
                                rows={'auto'}
                                handleChange={(e) => handleChangeReport(e, 'Note')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberForm
                                label='상담 횟수'
                                required={false}
                                error={false}
                                value={props.client.Report?.Count}
                                handleChange={(e) => handleChangeReport(e, 'Count')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Report?.createdAt ? YyyyMmDdHhMm(props.client.Report.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} spacing={1} justifyContent={'flex-end'} container>
                            <Grid item>
                                <SmallCancelButton
                                    label='PDF'
                                    handleClick={() => window.open(`${window.location.origin}/basic/pdf/${clientNo}/report`)}
                                />
                            </Grid>
                            <Grid item>
                                <SmallSubmitButton
                                    label='저장'
                                    handleClick={props.handleClickReport}
                                    disabled={!props.client.Report?.update || !props.client.Report.StartDay || !props.client.Report.EndDay}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='의뢰서'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.Request?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.Request?.Name ? props.client.Request.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.Request?.Note}
                                disabled={false}
                                rows={'auto'}
                                handleChange={(e) => handleChangeRequest(e, 'Note')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.Request?.createdAt ? YyyyMmDdHhMm(props.client.Request.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} spacing={1} justifyContent={'flex-end'} container>
                            <Grid item>
                                <SmallCancelButton
                                    label='PDF'
                                    handleClick={() => window.open(`${window.location.origin}/basic/pdf/${clientNo}/request`)}
                                />
                            </Grid>
                            <Grid item>
                                <SmallSubmitButton
                                    label='저장'
                                    handleClick={props.handleClickRequest}
                                    disabled={!props.client.Request?.update}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
            <Grid item xs={12} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <DetailContainer
                    title='기타'
                    collapse={180}
                    shadow='none'
                    h4={true}
                    pen={props.client.ETC?.Note ? 'primary' : 'secondary'}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextForm
                                label='제목'
                                required={false}
                                error={false}
                                value={props.client.ETC?.Title}
                                handleChange={(e) => handleChangeETC(e, 'Title')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextForm
                                label='상담자'
                                required={false}
                                error={false}
                                value={props.client.ETC?.Name ? props.client.ETC.Name : props.client.Counselor_id.Name}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='내용'
                                required={false}
                                error={false}
                                value={props.client.ETC?.Note}
                                disabled={false}
                                rows={'auto'}
                                handleChange={(e) => handleChangeETC(e, 'Note')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextForm
                                label='작성날짜'
                                required={false}
                                error={false}
                                value={props.client.ETC?.createdAt ? YyyyMmDdHhMm(props.client.ETC.createdAt) : YyyyMmDdHhMm(new Date())}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} spacing={1} justifyContent={'flex-end'} container>
                            <Grid item>
                                <SmallCancelButton
                                    label='PDF'
                                    handleClick={() => window.open(`${window.location.origin}/basic/pdf/${clientNo}/etc`)}
                                />
                            </Grid>
                            <Grid item>
                                <SmallSubmitButton
                                    label='저장'
                                    handleClick={props.handleClickETC}
                                    disabled={!props.client.ETC?.update}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DetailContainer>
            </Grid>
        </Grid>
        {basicScale ? 
        <>
        <FormModal
            title='URL 전송'
            open={openUrlSendModal}
            handleClose={() => handleCloseUrlSend()}
            submitLabel='확인'
            handleClickSubmit={() => { props.handleClickUrlSendBtn('basic', itemId);handleCloseUrlSend(); }}
            disabled={!props.send.Email && !props.send.Kakao}
        >
            <FormControl fullWidth>
                <FormGroup>
                    <FormControlLabel 
                        value={props.send.Email}
                        onChange={() => props.setSend({ ...props.send, Email: !props.send.Email })}
                        control={<Checkbox checked={props.send.Email}/>}
                        label={`이메일 전송 (${props.client.Email_decrypt})`}
                    />
                    <FormControlLabel 
                        value={props.send.Kakao}
                        onChange={() => props.setSend({ ...props.send, Kakao: !props.send.Kakao })}
                        disabled={!Boolean(props.client.Phone_decrypt) || !Boolean(props.client.Phone_decrypt.indexOf('010') > -1)}
                        control={<Checkbox checked={props.send.Kakao}/>}
                        label={`카카오톡 전송 ${props.client.Phone_decrypt ? `(${props.client.Phone_decrypt})` : ''}`}
                    />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
        </FormModal>
        <ConfirmModal
            title='기본 서류 초기화'
            open={openResetModal}
            handleClose={() => setOpenResetModal(false)}
            submitLabel='초기화'
            handleClickSubmit={() => {setOpenResetModal(false);props.handleClickBasicDeleteBtn(itemId)}}
        >
            초기화 버튼 클릭 시 기본 서류가 초기화 됩니다. 초기화 시 이전 기본 서류 정보를 복구할 수 없습니다.
        </ConfirmModal>
        </>
        :null}
        </>
    );
};

export default BasicView;